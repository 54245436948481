import { createSlice } from '@reduxjs/toolkit';
import { MyProfileInfo } from 'config/types/profile';
import { getBalanceTokenKey } from 'utils';
import { updateBalances, updateMyProfile } from './actions';

export interface ProfileState {
  balances: { [tokenKey: string]: string };
  percentage: number;
  info: MyProfileInfo;
}

export const initialProfileState: ProfileState = {
  balances: {},
  percentage: 0,
  info: {
    address: '',
    referralCode: '',
    referralsNumber: 0,
    referredBy: '',
    socketId: '',
    uid: '',
    userAvatar: '/images/profile/avatar.png',
    username: '',
    balances: null, // remove balances after have api payment
  },
};

const profileSlice = createSlice({
  name: 'Profile',
  initialState: initialProfileState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBalances, (state, { payload }) => {
        const { data } = payload;
        data.forEach((item) => {
          const tokenName = getBalanceTokenKey(item.network, item.currency);
          state.balances[tokenName] = item.amount;
        });
        return state;
      })
      .addCase(updateMyProfile, (state, { payload }) => {
        const { data } = payload;
        state.info = data;
        return state;
      });
  },
});

export default profileSlice.reducer;
