import { createSlice } from '@reduxjs/toolkit';
import { DepositedTokenInfo, RefToken } from 'config/types';
import { TokenUsdPriceResponse } from 'services/types';
import {
  initMetadata,
  selectPlayBalance,
  setIsSigned,
  setLayoutRendered,
  setIsInGame,
  updateTokenUsdPrices,
  updateDeviceUid,
} from './actions';

export interface AppState {
  tokenInfoes: DepositedTokenInfo[];
  tokenUsdPrices: TokenUsdPriceResponse;
  networkIds: string[];
  serverTimeMargin: number;
  deviceUid: string;
  serverTime: number;
  isSigned: boolean;
  signedAtTime: number;
  selectToken: RefToken;
  isLayoutRendered: boolean;
  isInGame: boolean;
}

const initialState: AppState = {
  isLayoutRendered: false,
  serverTimeMargin: 0,
  serverTime: null,
  deviceUid: '',
  tokenInfoes: [],
  networkIds: [],
  isSigned: null,
  selectToken: null,
  tokenUsdPrices: {},
  signedAtTime: null,
  isInGame: false,
};

export const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initMetadata, (state, { payload }) => {
        const { data } = payload;
        state.tokenInfoes = data?.tokenInfoes;
        state.networkIds = data?.networkIds;
        state.serverTime = data?.serverTime;
        const serverTimeMargin = new Date().getTime() - (data?.serverTime || 0);
        state.serverTimeMargin = Math.abs(serverTimeMargin) > 10000 ? serverTimeMargin : 0;

        if (
          (!state.selectToken || !state.selectToken.token || !state.selectToken.network) &&
          data.tokenInfoes.length > 0
        ) {
          state.selectToken = {
            token: data?.tokenInfoes[0].token,
            network: data?.tokenInfoes[0].network,
          };
        }

        return state;
      })
      .addCase(selectPlayBalance, (state, { payload }) => {
        const { token } = payload;

        state.selectToken = {
          token: token.name,
          network: token.network,
        };

        return state;
      })

      .addCase(updateDeviceUid, (state, { payload }) => {
        const { deviceUid } = payload;

        state.deviceUid = deviceUid;

        return state;
      })

      .addCase(setIsSigned, (state, { payload }) => {
        const { isSigned, atTime } = payload;
        state.isSigned = isSigned;
        state.signedAtTime = atTime || null;
        return state;
      })
      .addCase(setLayoutRendered, (state) => {
        state.isLayoutRendered = true;
        return state;
      })
      .addCase(updateTokenUsdPrices, (state, { payload }) => {
        state.tokenUsdPrices = { ...state.tokenUsdPrices, ...payload.data };
        return state;
      })

      .addCase(setIsInGame, (state, { payload }) => {
        state.isInGame = payload;
        return state;
      });
  },
});

export default appSlice.reducer;
