/* eslint-disable react/no-unstable-nested-components */
import { ErrorBoundary as SErrorBoundary } from '@sentry/nextjs';
import React from 'react';
import Error from 'views/Error';

const SentryErrorBoundary = ({ children }: { children?: React.ReactNode }) => {
  return (
    <SErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel('fatal');
      }}
      fallback={() => {
        return <Error />;
      }}
    >
      {children}
    </SErrorBoundary>
  );
};

export default SentryErrorBoundary;
