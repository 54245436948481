import { Network } from 'config/types';

const path = '/images/network';

type ListNetworkByChainId = {
  [key: string]: Network;
};

export enum ChainIdEnum {
  BSC = 56,
  BSC_TESTNET = 97,

  ETH = 1,
  ETH_TESTNET = 11155111,

  AVAX = 43114,
  AVAX_TESTNET = 43113,

  FTM = 250,
  FTM_TESTNET = 4002,

  MATIC = 137,
  MATIC_TESTNET = 80001,

  // SOL = -1,
  // SOL_TESTNET = -10,
  HPO = -2,
}

export const NETWORK_MAP: ListNetworkByChainId = {
  [ChainIdEnum.BSC]: {
    code: 'BSC',
    chainId: ChainIdEnum.BSC,
    rpcCollections: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
    ],
    blockExplorerUrls: 'https://bscscan.com',
    blockExplorerName: 'BSC Scan',
    networkInfo: {
      displayName: 'Binance Smart Chain',
      icon: `${path}/bsc.png`,
      shortName: 'BSC',
      native: {
        decimals: 18,
        name: 'BNB',
      },
    },
  },

  [ChainIdEnum.BSC_TESTNET]: {
    code: 'BSC_TESTNET',
    chainId: ChainIdEnum.BSC_TESTNET,
    rpcCollections: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: 'https://testnet.bscscan.com',
    blockExplorerName: 'BSC Scan',
    networkInfo: {
      displayName: 'Binance Smart Chain Testnet',
      icon: `${path}/bsc.png`,
      shortName: 'BSC',
      native: {
        decimals: 18,
        name: 'BNB',
      },
    },
  },

  [ChainIdEnum.ETH_TESTNET]: {
    code: 'ETH_TEST',
    chainId: ChainIdEnum.ETH_TESTNET,
    rpcCollections: ['https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: 'https://sepolia.etherscan.io/',
    blockExplorerName: 'ETH Goerli Scan',
    networkInfo: {
      displayName: 'Ethereum',
      icon: `${path}/eth.png`,
      shortName: 'Ethereum',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.ETH]: {
    code: 'ETH',
    chainId: ChainIdEnum.ETH,
    rpcCollections: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: 'https://etherscan.io/',
    blockExplorerName: 'Etherscan',
    networkInfo: {
      displayName: 'Ethereum',
      icon: `${path}/eth.png`,
      shortName: 'Ethereum',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.AVAX]: {
    code: 'AVAX',
    chainId: ChainIdEnum.AVAX,
    rpcCollections: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: 'https://snowtrace.io',
    blockExplorerName: 'Snowtrace',
    networkInfo: {
      displayName: 'Avalanche C Chain',
      icon: `${path}/avax.png`,
      shortName: 'Avalanche',
      native: {
        decimals: 18,
        name: 'AVAX',
      },
    },
  },

  [ChainIdEnum.AVAX_TESTNET]: {
    code: 'AVAX_FUJI',
    chainId: ChainIdEnum.AVAX_TESTNET,
    rpcCollections: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: 'https://testnet.snowtrace.io',
    blockExplorerName: 'Snowtrace (Testnet)',
    networkInfo: {
      displayName: 'Avalanche Juji Chain',
      icon: `${path}/avax.png`,
      shortName: 'Avalanche',
      native: {
        decimals: 18,
        name: 'AVAX',
      },
    },
  },

  [ChainIdEnum.FTM]: {
    code: 'FTM',
    chainId: ChainIdEnum.FTM,
    rpcCollections: ['https://rpc.ftm.tools'],
    blockExplorerUrls: 'https://ftmscan.com',
    blockExplorerName: 'FTM scan',
    networkInfo: {
      displayName: 'Fantom',
      icon: `${path}/ftm.png`,
      shortName: 'Fantom',
      native: {
        decimals: 18,
        name: 'OKT',
      },
    },
  },
  [ChainIdEnum.FTM_TESTNET]: {
    code: 'FTM_TESTNET',
    chainId: ChainIdEnum.FTM_TESTNET,
    rpcCollections: ['https://xapi.testnet.fantom.network/lachesis/'],
    blockExplorerUrls: 'https://testnet.ftmscan.com',
    blockExplorerName: 'FTM scan',
    networkInfo: {
      displayName: 'Fantom',
      icon: `${path}/ftm.png`,
      shortName: 'Fantom',
      native: {
        decimals: 18,
        name: 'FTM',
      },
    },
  },
  [ChainIdEnum.MATIC]: {
    code: 'MATIC',
    chainId: ChainIdEnum.MATIC,
    rpcCollections: ['https://polygon-rpc.com/'],
    blockExplorerUrls: 'https://polygonscan.com',
    blockExplorerName: 'Polygon scan',
    networkInfo: {
      displayName: 'Polygon',
      icon: `${path}/matic.png`,
      shortName: 'Polygon',
      native: {
        decimals: 18,
        name: 'MATIC',
      },
    },
  },
  [ChainIdEnum.MATIC_TESTNET]: {
    code: 'MATIC_TESTNET',
    chainId: ChainIdEnum.MATIC_TESTNET,
    rpcCollections: ['https://rpc-mumbai.maticvigil.com/'],
    blockExplorerUrls: 'https://mumbai.polygonscan.com',
    blockExplorerName: 'Polygon scan',
    networkInfo: {
      displayName: 'Polygon',
      icon: `${path}/matic.png`,
      shortName: 'Polygon',
      native: {
        decimals: 18,
        name: 'MATIC',
      },
    },
  },
  [ChainIdEnum.HPO]: {
    code: 'HPO',
    chainId: ChainIdEnum.HPO,
    rpcCollections: [''],
    blockExplorerUrls: '',
    blockExplorerName: '',
    networkInfo: {
      displayName: '',
      icon: '',
      shortName: '',
      native: {
        decimals: 1,
        name: 'USD',
      },
    },
  },
  // [ChainIdEnum.SOL]: {
  //   code: 'SOL',
  //   chainId: -1,
  //   rpcCollections: ['https://solana-mainnet.g.alchemy.com/v2/JH7TEGB_ecM-SpBFtuimdmH2MrfC5-Tg'],
  //   blockExplorerUrls: 'https://solscan.io',
  //   blockExplorerName: 'SolScan',
  //   networkInfo: {
  //     displayName: 'SOLANA',
  //     icon: `${path}/sol.png`,
  //     shortName: 'Solana',
  //     native: {
  //       decimals: 9,
  //       name: 'SOL',
  //     },
  //   },
  // },
  // [ChainIdEnum.SOL_TESTNET]: {
  //   code: 'SOL_TESTNET',
  //   chainId: -10,
  //   rpcCollections: [clusterApiUrl(WalletAdapterNetwork.Testnet)],
  //   blockExplorerUrls: 'https://solscan.io',
  //   blockExplorerName: 'SolScan',
  //   networkInfo: {
  //     displayName: 'SOLANA',
  //     icon: `${path}/sol.png`,
  //     shortName: 'Solana',
  //     native: {
  //       decimals: 9,
  //       name: 'SOL',
  //     },
  //   },
  // },
};

export const WALLETCONNECT_SUPPORTED = [
  NETWORK_MAP[ChainIdEnum.BSC],
  NETWORK_MAP[ChainIdEnum.BSC_TESTNET],
  NETWORK_MAP[ChainIdEnum.ETH],
  NETWORK_MAP[ChainIdEnum.ETH_TESTNET],
  NETWORK_MAP[ChainIdEnum.AVAX],
  NETWORK_MAP[ChainIdEnum.AVAX_TESTNET],
  NETWORK_MAP[ChainIdEnum.FTM],
  NETWORK_MAP[ChainIdEnum.FTM_TESTNET],
  NETWORK_MAP[ChainIdEnum.MATIC],
  NETWORK_MAP[ChainIdEnum.MATIC_TESTNET],
];

export const CHAIN_IDS = Object.values(ChainIdEnum).filter((item) => Number.isInteger(item)) as number[];
