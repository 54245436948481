/* eslint-disable no-param-reassign */
import { Time } from 'config/constants';
import { LANGUAGES } from 'config/constants/languages';
import { ChainIdEnum } from 'config/constants/network';
import { DEV_MODE } from 'config/constants/server';
import { ApiStatusCode } from 'services/types';
import { isHPO } from './network';

export const getLangCodeByBCP47 = (code: string) => {
  const supportedLng = LANGUAGES.map((item) => item.code);
  let _lang = 'en';

  const parsedCode = code.toLowerCase();
  if (parsedCode.includes('ko')) {
    _lang = 'ko';
  }

  if (parsedCode.includes('zh')) {
    _lang = code === 'zh-cn' ? 'zh-Hans' : 'zh-Hant';
  }

  if (parsedCode.includes('vi')) {
    _lang = 'vi';
  }

  if (parsedCode.includes('ar')) {
    _lang = 'ar';
  }

  if (parsedCode.includes('pt-BR')) {
    _lang = 'pt-BR';
  }

  if (parsedCode.includes('pt-PT')) {
    _lang = 'pt-PT';
  }

  if (parsedCode.includes('fr')) {
    _lang = 'fr';
  }

  if (parsedCode.includes('pt-PT')) {
    _lang = 'pt-PT';
  }

  if (parsedCode.includes('id')) {
    _lang = 'id';
  }

  if (parsedCode.includes('es')) {
    _lang = 'es';
  }

  if (parsedCode.includes('tr')) {
    _lang = 'tr';
  }

  return supportedLng.includes(_lang) ? _lang : 'en';
};

export const formatApiNetworkField = (network: string, devMode: boolean = DEV_MODE) => {
  // exp: BSC:TESTNET -> BSC_TESTNET

  if (isHPO(network)) return network;

  if (devMode) {
    if (!network.includes(':')) {
      return `${network}_TESTNET`;
    }
    return network.replace(':', '_TESTNET');
  }
  return network.replace(':', '_');
};  

export const formatToApiNetworkField = (network: string, devMode: boolean = DEV_MODE) => {
  if (isHPO(network)) return network;

  if (devMode) {
    return network.replace('_TESTNET', '').replace(':TESTNET', '');
  }
  return network?.replace('_', ':');
};

export const getChainCode = (network: string) => {
  if (network === 'SOL') {
    return 'mainnet-beta';
  }

  if (network === 'SOL_TESTNET') {
    return 'testnet';
  }

  return network?.replace('_', ':');
};

export const getBalanceTokenKey = (network: ChainIdEnum, currency: string) => {
  return `${network}_${currency}`;
};

export const delayed = (delayedTime: number): Promise<boolean> => {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(true);
      clearTimeout(timer);
    }, delayedTime);
  });
};

export function timeSince(time: number): [number, Time] {
  const seconds = Math.floor((new Date().getTime() - time) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return [Math.floor(interval), Time.YEAR];
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return [Math.floor(interval), Time.MONTH];
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return [Math.floor(interval), Time.DAY];
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return [Math.floor(interval), Time.HOUR];
  }
  interval = seconds / 60;
  if (interval > 1) {
    return [Math.floor(interval), Time.MIN];
  }
  return [Math.floor(interval * 60), Time.SEC];
}

/// Summarize address
export const shortedAddress = (account: string, firstIdx = 6, endIdx = 4) => {
  if (!account) return null;

  return `${account?.substring(0, firstIdx)}...${account?.substring(account.length - endIdx, account.length)}`;
};

export const revalidateHunnyLevel = (level: number) => {
  const displayLevel = level || 0;
  return displayLevel > 0 ? displayLevel - 1 : 0;
};

export const range = (start: number, end: number) => {
  const result = [];

  for (let index = start; index < end; index++) {
    result.push(index);
  }

  return result;
};

export const emptyArray = (size: number) => {
  const result = [];

  for (let index = 0; index < size; index++) {
    result.push(null);
  }

  return result;
};

export function parselistToMatrix(list, elementsPerSubArray) {
  const matrix = [];
  for (let i = 0, k = -1; i < list?.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }
  return matrix;
}

export function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

export function buildHandleImageLoad(ctx: CanvasRenderingContext2D, onComplete: (url: string) => void) {
  return (evt: any) => {
    const img = evt.target;
    window.URL.revokeObjectURL(img.src);
    ctx.canvas.width = 256;
    ctx.canvas.height = 256;
    ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);

    const dataUrl = ctx.canvas.toDataURL();

    ctx.canvas.width = 1;
    ctx.canvas.height = 1;
    onComplete(dataUrl);
  };
}

export function getBase64(picURL: string, onComplete: (url: string) => void): void {
  const ctx = window.document.createElement('canvas').getContext('2d');
  const img = new window.Image();
  img.addEventListener('load', buildHandleImageLoad(ctx, onComplete));
  img.src = picURL;
}

export function buildPluralizeText(
  templateText: string,
  params: { number: number; word: [string, string]; key: string }[],
) {
  const result = params.reduce((result, { key, number, word }) => {
    result = result.replaceAll(`{{${key}}}`, number > 1 ? word[1] : word[0]);
    return result;
  }, templateText);

  return result;
}

export const isAPICodeSuccess = (code: string) => code === ApiStatusCode.Success;
