import { useEffect, useMemo, useRef, useState } from 'react';

const useComponentOnScreen = (observerOptions: IntersectionObserverInit) => {
  const ref = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver;

    if (ref.current) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setIsLoaded(true);
            observer.disconnect();
          }
        });
      }, observerOptions);
      observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return useMemo(() => ({ isLoaded, ref }), [isLoaded]);
};

export default useComponentOnScreen;
