import { createAction } from '@reduxjs/toolkit';
import { Token } from 'config/types';
import { MetaDataResponse, TokenUsdPriceResponse } from 'services/types';

export const initMetadata = createAction<{ data: MetaDataResponse }>('app/initMetadata');
export const updateDeviceUid = createAction<{ deviceUid: string }>('app/updateDeviceUid');
export const setIsSigned = createAction<{ isSigned: boolean; atTime?: number }>('app/setIsSigned');
export const selectPlayBalance = createAction<{ token: Token }>('app/selectPlayBalance');
export const updateTokenUsdPrices = createAction<{ data: TokenUsdPriceResponse }>('app/updateTokenUsdPrices');
export const setLayoutRendered = createAction('app/setLayoutRendered');
export const setIsInGame = createAction<boolean>('app/setIsInGame');
