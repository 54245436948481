import { Token } from 'config/types';
import { ChainIdEnum } from '../network';

const path = '/images/token';

const tokens: Record<string, Token> = {
  USDB: {
    logo: `${path}/usd-bonus.png`,
    name: 'USDB',
    code: 'USDB',
    network: ChainIdEnum.HPO,
    isNative: true,
    decimals: 1,
  },
  USD: {
    logo: `${path}/usd.png`,
    name: 'USD',
    code: 'USD',
    network: ChainIdEnum.HPO,
    isNative: true,
    decimals: 1,
  },
};

export default tokens;
