import { RouteConfig } from './constants/route';
import { APP_SITE } from './constants/server';

export type PageMeta = {
  title: string;
  description?: string;
  ogImage?: string;
};

export const DEFAULT_META: PageMeta = {
  title: 'HunnyPoker | The Most Engaging And Fun Poker Game',
  description:
    'Join Hunnypoker - Elevate Your Poker Experience with the Ultimate Blend of Excitement and Entertainment!',
  ogImage: `${APP_SITE}/hunnypoker-banner.png`,
};

export const getCustomMeta = (path: RouteConfig, t: (text: string) => string = (text) => text): PageMeta => {
  if (path.startsWith(RouteConfig.Play)) {
    return {
      ...DEFAULT_META,
      title: `${t('Poker')} | ${t('HunnyPoker')}`,
    };
  }
  return {
    ...DEFAULT_META,
  };
};
