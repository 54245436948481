import { createSlice } from '@reduxjs/toolkit';
import { LoginMethod } from 'config/constants/auth';
import { WalletType } from 'config/types/wallet';
import { login, logout, removeEmail, signedExecuteLogin, updateAuthToken } from './action';

export interface AuthState {
  loginBy: LoginMethod;
  username: string;
  uid: string;

  wallet?: {
    type: WalletType;
    connectorId: string;
    name: string;

    address?: string;
    trackingName?: string;
  };
  email: string;
  accessToken: string;
  refreshToken: string;
  address: string;
}

export const initialState: AuthState = {
  loginBy: null,
  username: null,
  uid: null,
  wallet: {
    type: null,
    connectorId: '',
    name: '',

    address: '',
    trackingName: '',
  },
  address: '',
  email: null,
  accessToken: '',
  refreshToken: '',
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAuthToken, (state, { payload }) => {
        return {
          ...state,
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken,
        };
      })
      .addCase(login, (state, { payload }) => {
        const { username, address, uid, email } = payload;
        return {
          ...state,
          address,
          username,
          uid,
          email,
        };
      })
      .addCase(signedExecuteLogin, (state, { payload }) => {
        if (payload.loginBy === LoginMethod.Wallet) {
          state.wallet = payload.wallet;
        }
        state.loginBy = payload.loginBy;
        return state;
      })
      .addCase(logout, (state) => {
        state = {
          ...initialState,
          email: state.email,
        };

        return state;
      })
      .addCase(removeEmail, (state) => {
        state.email = null;
        return state;
      });
  },
});

export default authReducer.reducer;
