import { Token } from 'config/types';
import { ChainIdEnum } from '../network';

const path = '/images/token';

const tokens: Record<string, Token> = {
  BNB: {
    coinGeckoInfo: {
      id: 'binancecoin',
    },
    logo: `${path}/bnb.png`,
    name: 'BNB',
    code: 'BNB',
    network: ChainIdEnum.BSC,
    isNative: true,
    decimals: 18,
  },
  HUNNY: {
    coinGeckoInfo: {
      id: 'pancake-hunny',
    },
    logo: `${path}/hunny.png`,
    name: 'HUNNY',
    code: 'HUNNY',
    network: ChainIdEnum.BSC,
    address: '0x565b72163f17849832A692A3c5928cc502f46D69',
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.BSC,
    address: '0x55d398326f99059ff775485246999027b3197955',
    decimals: 18,
    isStable: true,
  },
  CAKE: {
    coinGeckoInfo: {
      id: 'pancakeswap-token',
    },
    logo: `${path}/cake.png`,
    name: 'CAKE',
    code: 'CAKE',
    network: ChainIdEnum.BSC,
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    decimals: 18,
  },
  BUSD: {
    coinGeckoInfo: {
      id: 'binance-usd',
    },
    logo: `${path}/busd.png`,
    name: 'BUSD',
    code: 'BUSD',
    network: ChainIdEnum.BSC,
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    decimals: 18,
    isStable: true,
  },
  LOVE: {
    coinGeckoInfo: {
      id: 'hunny-love-token',
    },
    logo: `${path}/love.png`,
    name: 'LOVE',
    code: 'LOVE',
    network: ChainIdEnum.BSC,
    address: '0x9505dbd77dacd1f6c89f101b98522d4b871d88c5',
    decimals: 9,
  },
  BABY: {
    coinGeckoInfo: {
      id: 'babyswap',
    },
    logo: `${path}/baby.png`,
    name: 'BABY',
    code: 'BABY',
    network: ChainIdEnum.BSC,
    address: '0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657',
    decimals: 18,
  },
  VAI: {
    coinGeckoInfo: {
      id: 'vai',
    },
    logo: `${path}/vai.png`,
    name: 'VAI',
    code: 'VAI',
    network: ChainIdEnum.BSC,
    address: '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7',
    decimals: 18,
  },
  XVS: {
    coinGeckoInfo: {
      id: 'venus',
    },
    logo: `${path}/xvs.png`,
    name: 'XVS',
    code: 'XVS',
    network: ChainIdEnum.BSC,
    address: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
    decimals: 18,
  },

  TIPSY: {
    coinGeckoInfo: {
      id: 'tipsy',
    },
    logo: `${path}/tipsy.png`,
    name: 'TIPSY',
    code: 'TIPSY',
    network: ChainIdEnum.BSC,
    address: '0xf380334b9A181F54BF2f32FDD3e153D259472eBB',
    decimals: 18,
  },
};

export default tokens;
