import { Token } from 'config/types';
import bscTokens from './bsc';
import bscTestnetTokens from './bsc_testnet';
import avaxTokens from './avax';
import avaxTestnetTokens from './avax_testnet';
import ftmTokens from './ftm';
import ftmTestnetTokens from './ftm_testnet';
import maticTokens from './polygon';
import maticTestnetTokens from './polygon_testnet';
import hpoTokens from './hpo';
import ethTestTokens from './eth_testnet';
import ethTokens from './eth';
import { ChainIdEnum } from '../network';

export default {
  [ChainIdEnum.BSC]: bscTokens,
  [ChainIdEnum.ETH]: ethTokens,
  [ChainIdEnum.AVAX]: avaxTokens,
  [ChainIdEnum.FTM]: ftmTokens,
  [ChainIdEnum.MATIC]: maticTokens,

  [ChainIdEnum.BSC_TESTNET]: bscTestnetTokens,
  [ChainIdEnum.AVAX_TESTNET]: avaxTestnetTokens,
  [ChainIdEnum.FTM_TESTNET]: ftmTestnetTokens,
  [ChainIdEnum.MATIC_TESTNET]: maticTestnetTokens,

  [ChainIdEnum.ETH_TESTNET]: ethTestTokens,

  [ChainIdEnum.HPO]: hpoTokens,
};

export const tokenBlackList: Token[] = [];
