import { Token } from 'config/types';
import { ChainIdEnum } from '../network';

const path = '/images/token';

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.ETH_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.ETH_TESTNET,
    address: '0xe84f469e6b55935183377a6dbe0de6bd12df4448',
    decimals: 6,
    isStable: true,
  },
};

export default tokens;
