import { ChainIdEnum } from 'config/constants/network';
import { DepositedTokenInfo, Token } from 'config/types';
import { ReferralBalanceTokenAmount, ReferralCampaign, ReferralCampaignStats } from 'config/types/referral';
import { WalletType } from 'config/types/wallet';

export class BaseResponse<T> {
  code: string;

  message?: string;

  errors?: any[];

  data?: T;
}

export enum ApiStatusCode {
  Success = 'success',
  Error = 'error',
}

export class Paging<T> {
  paging?: PagingInfo;

  items: T[];
}

export class PagingInfo {
  limit?: number;

  offset?: number;

  total?: number;
}

export type MetaDataResponse = {
  serverTime: number;
  tokenInfoes: DepositedTokenInfo[];
  networkIds: string[];
};

export class HunnyRequest<T = any> {
  call: () => Promise<T>;

  cancel: () => void;
}

export type QueryGhostParams<T = any> = {
  fields?: (keyof T)[];
  filter?: string;
  order?: string;
  include?: string[];
  limit?: number | 'all';
  page?: number;
};
export class PrepareSignMessagePayload {
  walletType: WalletType;

  address?: string;
}
export class PrepareSignMessageResponse {
  address: string;

  acceptMessageText: string;

  acceptMessage: any;
}

export class SignInByWalletPayload {
  acceptMessage: string;

  address: string;

  deviceUid: string;

  messageSignature: string;

  referralCode?: string;

  affilkaCode?: string;
}

export class SignInByEmailPayload {
  password: string;

  email: string;

  deviceUid: string;
}

export class LoginResponse {
  accessToken: string;

  refreshToken: string;

  uid?: number;
}

export class BalanceResponse {
  currency: string;

  amount: string;

  network: ChainIdEnum;
}

export class TokenUsdPriceResponse {
  [nameToken: string]: {
    usd: string;
  };
}

export class TraditionalSignUpPreparePayload {
  email: string;

  referralCode: string;
}

export class DeepLinkResponse {
  token: string;
}

export class PasswordNonceResponse {
  id: string;

  value: string;

  key: string;

  token: string;

  resetToken?: string;
}

export class VerificationResponse {
  expireTime: number;

  nextResendTime: number;

  token: string;
}

export class Verification {
  token: string;

  otp: string;

  expireTime?: number;

  expiredTime?: number;

  nextResendTime?: number;
}
export interface RegisteredEmailPayload {
  email: string;
  password: string;
  verification: Verification;
  referralCode?: string;
  deviceUid: string;
}

export interface PrepareTokenNetworkPayload {
  currency: Token;
  amount: string;
  toAddress?: string;
}

export interface PrepareWithdrawPayload extends PrepareTokenNetworkPayload {
  toAddress: string;
  otp: string;
  verificationCode?: string;
}

export interface WithdrawResponse {
  ref: number;
  createdAt: number;
}

export interface PrepareReferralClaim extends PrepareTokenNetworkPayload {
  address: string;
  items: ReferralBalanceTokenAmount[];
}

export interface PrepareReferralClaimRes {
  items: ReferralBalanceTokenAmount[];
  sum: string;
}

export interface ReferralCampaignDetailRes {
  campaign: ReferralCampaign;
  statsList?: ReferralCampaignStats[];
  statsTimeRange?: {
    fromTime: number;
    toTime: number;
  };
}
