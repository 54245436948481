import { createReducer } from '@reduxjs/toolkit';
import { Transaction } from 'config/types/transaction';
import {
  addPendingDepositTransaction,
  addPendingWithdrawTransaction,
  clearPendingTransaction,
  removePendingDepositTransaction,
  removePendingWithdrawTransaction,
  updateDepositPendingTransaction,
  updateWithdrawPendingTransaction,
} from './action';

export interface TransactionState {
  pendingWithdrawTransactionCode: Transaction[];
  pendingDepositTransactionHash: Transaction[];
}

const initialState: TransactionState = {
  pendingWithdrawTransactionCode: [],
  pendingDepositTransactionHash: [],
};

const transactionSlice = createReducer(initialState, (builder) => {
  builder.addCase(clearPendingTransaction, (state) => {
    state.pendingWithdrawTransactionCode = [];
    state.pendingDepositTransactionHash = [];
    return state;
  });
  builder.addCase(addPendingWithdrawTransaction, (state, { payload }) => {
    state.pendingWithdrawTransactionCode = [payload, ...(state.pendingWithdrawTransactionCode || [])];
    return state;
  });
  builder.addCase(updateDepositPendingTransaction, (state, { payload }) => {
    state.pendingDepositTransactionHash = [
      payload,
      ...(state.pendingDepositTransactionHash || []).filter((txn) => txn.txnHash !== payload.txnHash),
    ];
    return state;
  });

  builder.addCase(removePendingWithdrawTransaction, (state, { payload }) => {
    const pendingWithdrawTransactionCode = state.pendingWithdrawTransactionCode.filter(
      (txn) => Number(txn.txnHash) !== Number(payload.code),
    );
    state.pendingWithdrawTransactionCode = pendingWithdrawTransactionCode;
    return state;
  });
  builder.addCase(updateWithdrawPendingTransaction, (state, { payload }) => {
    state.pendingWithdrawTransactionCode = [
      payload,
      ...(state.pendingWithdrawTransactionCode || []).filter((txn) => txn.txnHash !== payload.txnHash),
    ];
  });

  builder.addCase(addPendingDepositTransaction, (state, { payload }) => {
    state.pendingDepositTransactionHash = [payload, ...(state.pendingDepositTransactionHash || [])];
    return state;
  });
  builder.addCase(removePendingDepositTransaction, (state, { payload }) => {
    const pendingDepositTransactionHash = state.pendingDepositTransactionHash.filter(
      (txn) => txn.txnHash.toLowerCase() !== payload.hash.toLowerCase(),
    );
    state.pendingDepositTransactionHash = pendingDepositTransactionHash;
    return state;
  });
});

export default transactionSlice;
