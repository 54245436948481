import Script from 'next/script';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React, { Fragment, useEffect } from 'react';
import { NextPage } from 'next';
import { PersistGate } from 'redux-persist/integration/react';
import { useStore, persistor, useAppDispatch } from 'state';
import SentryErrorBoundary from 'components/ErrorBoundary';
import dynamic from 'next/dynamic';
import { setLayoutRendered } from 'state/app/actions';
import { useTranslation } from 'react-i18next';
import { DEFAULT_META } from 'config/meta';

const MainLayout = dynamic(() => import('layout'));
const Providers = dynamic(() => import('Providers'));
const GlobalHooks = dynamic(() => import('GlobalHooks'));

const MyApp = (props: any) => {
  const { pageProps } = props;
  const store = useStore(pageProps.initialReduxState);
  const pageMeta = pageProps.seoProps || DEFAULT_META;
  const { title, ogTitle, twitterTitle, description, ogDescription, twitterDescription, ogImage, twitterImage } =
    pageMeta;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#16191f" />
        <meta property="og:title" content={ogTitle || title} />
        <meta property="og:description" content={ogDescription || description} />
        <meta property="og:url" content="https://hunnypoker.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:site_name" content="HunnyPoker" />
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={twitterTitle || title} />
        <meta property="twitter:description" content={twitterDescription || description} />
        <meta property="twitter:image" content={twitterImage || ogImage} />
        <meta property="twitter:image:src" content={twitterImage || ogImage} />
        <title>{title}</title>
      </Head>

      <Providers store={store}>
        <GlobalHooks />
        <PersistGate loading={null} persistor={persistor}>
          <App {...props} />
        </PersistGate>
      </Providers>

      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        id="google-analytics"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${process.env.NEXT_PUBLIC_GA}');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        id="coinzilla-tag"
        dangerouslySetInnerHTML={{
          __html: `
          try {
            window.coinzilla_performance = window.coinzilla_performance || [];
            coinzilla_performance.push({ event: "register" });
          } catch (err) { }
          `,
        }}
      />
    </>
  );
};

type NextPageWithLayout = NextPage & {
  Layout?: React.FC;
  layoutProps: any;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: any;
};

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment;

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const Layout = Component.Layout || MainLayout;
  // this is will app rerender language if switch language
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  return (
    <ProductionErrorBoundary>
      <Layout {...pageProps.layoutProps}>
        <Component {...pageProps} />
        <RenderListender />
      </Layout>
    </ProductionErrorBoundary>
  );
};

const RenderListender = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setLayoutRendered());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default MyApp;
