import { ToastContainerProps } from 'react-toastify';

export const DEFAULT_TIME = { days: 0, hours: 0, minutes: 0, seconds: 0 };

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
} as ToastContainerProps;

export enum FetchingStatus {
  Unknown,
  Fetching,
  Fetched,
  Failed,
}

// copy of next.config.js images
export const IMG_SUPPORT_DOMAINS = [
  // {
  //   protocol: 'https:',
  //   hostname: 'media-new.hunny.finance',
  // },
  // {
  //   protocol: 'https:',
  //   hostname: 'media.hunny.finance',
  // },
  // {
  //   protocol: 'https:',
  //   hostname: 'review.hunnyplay.com',
  // },
];

export enum Time {
  SEC,
  MIN,
  HOUR,
  DAY,
  MONTH,
  YEAR,
}

export enum ConnectorNameEnum {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

export const emptyPagingReponse = {
  paging: {},
  items: [],
};

export const remainSearchParams = ['join', 'stag'];
