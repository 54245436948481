/* eslint-disable import/no-mutable-exports */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  createTransform,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app, { AppState } from './app';
import auth, { AuthState } from './auth';
import profile, { ProfileState } from './profile';
import system, { SystemState } from './system';
import transaction, { TransactionState } from './transaction';
import bonus, { BonusState } from './bonus';

const PERSISTED_KEYS: string[] = ['auth', 'app', 'profile', 'transaction'];

const whiteList = createTransform(
  (inboundState: any, key) => {
    if (key === 'app') {
      return {
        selectToken: inboundState.selectToken,
        tokenInfoes: inboundState.tokenInfoes,
        searchHistory: inboundState.searchHistory,
        deviceUid: inboundState.deviceUid,
      };
    }
    return inboundState;
  },
  (outBoundState: any, key) => {
    if (key === 'app') {
      return {
        selectToken: outBoundState.selectToken,
        tokenInfoes: outBoundState.tokenInfoes,
        searchHistory: outBoundState.searchHistory,
        deviceUid: outBoundState.deviceUid,
      };
    }
    return outBoundState;
  },
);

const persistConfig = {
  key: 'hunnypoker',
  whitelist: PERSISTED_KEYS,
  storage,
  transforms: [whiteList],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    app,
    profile,
    auth,
    system,
    bonus,
    transaction,
  }),
);

let store: ReturnType<typeof makeStore>;

function makeStore(preloadedState = undefined) {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'promotion/updatePinnedPromotions'],
          ignoredPaths: ['promotion'],
        },
      }),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState,
  });
}

export const initializeStore = (preloadedState = undefined) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;

  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

store = initializeStore();

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */

export type RootState = {
  app: AppState;
  profile: ProfileState;
  auth: AuthState;
  system: SystemState;
  transaction: TransactionState;
  bonus: BonusState;
};

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export const persistor = persistStore(store);

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
