export enum LoginMethod {
  Email = 'email',
  Wallet = 'wallet',
}

export enum TokenKeyEnum {
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  UserInfo = 'UserInfo',
}
