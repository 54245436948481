import { Token } from 'config/types';
import { ChainIdEnum } from '../network';

const path = '/images/token';

const tokens: Record<string, Token> = {
  MATIC: {
    coinGeckoInfo: {
      id: 'matic-network',
    },
    logo: `${path}/matic.png`,
    name: 'MATIC',
    code: 'MATIC',
    network: ChainIdEnum.MATIC_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.MATIC_TESTNET,
    address: '0xE18492c7CBD2b58e2AcB46Ab869aCEb3a4273FA1',
    decimals: 18,
    isStable: true,
  },
};

export default tokens;
