import { IMG_SUPPORT_DOMAINS } from 'config/constants';
import { StaticImageData } from 'next/image';
import { useMemo } from 'react';

const useNextImage = (src: string | StaticImageData) => {
  const isNextImgSupport = useMemo(() => {
    if (!src) return false;
    if (typeof src !== 'string' && src.blurDataURL) {
      return true;
    }

    try {
      const url = new URL(src as string);

      return !!IMG_SUPPORT_DOMAINS.find((item) => item.hostname === url.hostname && item.protocol === url.protocol);
    } catch (_) {
      // Local src
      return true;
    }
  }, [src]);

  const imgProps: any = useMemo(() => {
    if (!src) return false;
    if (typeof src !== 'string' && src.blurDataURL) {
      return {
        placeholder: 'blur',
      };
    }
    return {};
  }, [src]);

  return { isNextImgSupport, imgProps };
};

export default useNextImage;
