export enum APIEndpointEnum {
  Games = 'api/v1/game/list/',

  Meta = '/v1/merchant/meta/get/',
  TokenUsdPrices = 'https://api.coingecko.com/api/v3/simple/price',

  PrivateSocket = '/api/v1/socket.io/',
  PublicSocket = '/api/v1/socket.io/public',

  WalletLoginPrepare = '/api/v1/auth/login/wallet/prepare/',
  SignInByWallet = '/api/v1/auth/login/wallet/',

  PrepareTraditionalLogin = '/api/v1/auth/login/email/prepare/',
  SignInByEmail = '/api/v1/auth/login/email/',

  Register = '/api/v1/auth/signup/',
  DeepLink = '/api/v1/auth/deeplink/get/',
  Refresh = '/api/v1/auth/refresh/',
  SignUpPrepare = '/api/v1/auth/signup/prepare/',
  VerifyRegisterUser = '/api/v1/auth/signup/verification/request/',

  // forgot password
  VerifyResetPassword = '/api/v1/auth/login/email/password/reset/prepare/',
  ValidateResetPassword = '/api/v1/auth/login/email/password/reset/validate/',
  SubmitResetPassword = 'api/v1/auth/login/email/password/reset/',

  // Logout
  Logout = 'api/v1/auth/logout/',
  // profile
  Profile = 'api/v1/user/profile/get/',

  // transaction
  TransactionHistory = '/api/v1/txn/get/',
  DepositTransaction = 'api/v1/txn/deposit/detail/',
  DepositTransactions = 'api/v1/txn/deposit/get/',

  WithdrawTransactions = 'api/v1/txn/withdraw/get/',

  SubscribeDeposit = '/api/v1/txn/deposit/traditional/watcher/submit/',

  SubscribeDepositByHash = '/api/v1/txn/deposit/traditional/hash/submit/',
  QueryDepositByHash = '/api/v1/txn/deposit/traditional/hash/get/',

  Balances = 'api/v1/user/balance/get/',

  // tradition deposit
  TraditionalDeposit = '/api/v1/txn/deposit/traditional/prepare/',

  WithdrawFee = 'api/v1/txn/withdraw/fee/',

  // tradition withdraw
  TraditionalWithdraw = '/api/v1/txn/withdraw/traditional/submit',
  PrepareTraditionalWithdraw = '/api/v1/txn/withdraw/traditional/prepare/',

  // Wallet Withdraw
  WalletWithdraw = '/api/v1/txn/withdraw/wallet/',
  PrepareWalletWithdraw = '/api/v1/txn/withdraw/wallet/prepare/',

  WithdrawalTransaction = '/api/v1/txn/withdraw/detail/',
  WithdrawalTransactions = ' /api/v1/txn/withdraw/get/',

  // Bonus
  UserBonuses = '/api/v1/user/bonus/available/list/',
  BonusPrepareDeposit = '/api/v1/user/bonus/deposit/prepare/',
  UserBonusDetails = '/api/v1/user/bonus/detail/',
}

export const BASE_API = process.env.NEXT_PUBLIC_APP_API;

export const SOCKET_API = process.env.NEXT_PUBLIC_APP_SOCKET;

export const BASE_API_HUNNY = process.env.NEXT_PUBLIC_API_HUNNY;

export const APP_SITE = process.env.NEXT_PUBLIC_SITE;
export const SERVER_JSON = process.env.NEXT_PUBLIC_SERVER_JSON;

export const GAME_RESOURCE = JSON.parse(process.env.NEXT_PUBLIC_GAME_RESOURCE || '{}') || {};
export const APK_URL = process.env.NEXT_PUBLIC_APK_URL;
export const DEV_MODE = parseInt(process.env.NEXT_PUBLIC_DEVMODE, 10) === 1;
export const STAGING_MODE = parseInt(process.env.NEXT_PUBLIC_DEVMODE, 10) === 2;

export const MessageResponseServer = {
  emailExist: 'the email address already existed',
  unauthorized: 'unauthorized',
  expireSession: 'invalid refresh token',
};

export const EXCLUDE_API_ERROR = [
  MessageResponseServer.unauthorized,
  MessageResponseServer.expireSession,
  'error_data_not_found',
  'error_country_banned',
];
