import { createAction } from '@reduxjs/toolkit';
import { Transaction } from 'config/types/transaction';

export const addPendingDepositTransaction = createAction<Transaction>('transaction/addPendingDepositTransaction');

export const removePendingDepositTransaction = createAction<{ hash: string }>(
  'transaction/removePendingDepositTransaction',
);
export const updateDepositPendingTransaction = createAction<Transaction>('transaction/updateDepositPendingTransaction');

export const addPendingWithdrawTransaction = createAction<Transaction>(
  'transaction/addPendingTranaddPendingWithdrawTransactionsaction',
);

export const removePendingWithdrawTransaction = createAction<{ code: string }>(
  'transaction/removePendingWithdrawTransaction',
);
export const updateWithdrawPendingTransaction = createAction<Transaction>(
  'transaction/updateWithdrawPendingTransaction',
);

export const clearPendingTransaction = createAction('transaction/clearPendingTransaction');
