import getThemeValue from 'components/util';
import styled, { DefaultTheme } from 'styled-components';
import { space, typography, layout } from 'styled-system';
import { TextProps } from './types';

interface ThemedProps extends TextProps {
  theme: DefaultTheme;
}

const getColor = ({ color, theme }: ThemedProps) => {
  return getThemeValue(`colors.${color}`, color)(theme);
};

const getFontSize = ({ fontSize, small }: TextProps) => {
  return small ? '12px' : fontSize || '14px';
};

const getLineHeigth = ({
  fontSize,
  small,
  lineHeight,
}: TextProps & {
  lineHeight?: any;
}) => {
  return (
    lineHeight ||
    `calc(${getFontSize({
      fontSize,
      small,
    })} + 6px)`
  );
};

const Text = styled.div<TextProps>`
  color: ${getColor};
  font-size: ${getFontSize};
  line-height: ${getLineHeigth};
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  letter-spacing: -0.02em;
  white-space: ${({ whiteSpace }) => whiteSpace};

  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ ellipsis }) =>
    !!ellipsis &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${ellipsis};
    -webkit-box-orient: vertical;
    `}
    ${space} ${typography} ${layout};
`;

Text.defaultProps = {
  color: 'text',
  small: false,
};

export default Text;
