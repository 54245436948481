import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { WrapperProps } from './types';

const StyledWrapper = styled.div<{ $width: number; $height: number }>`
  max-height: ${({ $height }) => $height}px;
  max-width: ${({ $width }) => $width}px;
  /* height: 100%; */

  position: relative;
  display: flex;
  ${space}
`;

const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(({ width, height, ...props }, ref) => {
  return <StyledWrapper ref={ref} $width={width} $height={height} {...props} />;
});

export default Wrapper;
