import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network';
import Tokens from 'config/constants/tokens';
import { Network, Token } from 'config/types';

export const getNetworkInfo = (chainId: ChainIdEnum): { network: Network; native: Token } => {
  const listTokenByChain = Tokens[chainId];
  const networkInfo = NETWORK_MAP[chainId];

  const nativeToken: Token = Object.values(listTokenByChain)?.filter((token) => token.isNative)?.[0] || null;

  return {
    network: networkInfo,
    native: nativeToken,
  };
};

export const getBlockExplorerUrls = ({ chainId, txnHash }: { chainId: ChainIdEnum; txnHash: string }) => {
  const networkInfo = NETWORK_MAP[chainId];
  return networkInfo ? `${networkInfo.blockExplorerUrls}/tx/${txnHash}` : '';
};

export const parseNetworkToApiPoker = (networkCode: string) => {
  try {
    const [network, code] = networkCode.split(':');
    if (network === 'BNB') {
      return code ? `BSC:${code}` : 'BSC';
    }
    return networkCode;
  } catch {
    return networkCode;
  }
};

export const parseNetworkClientPoker = (networkCode: string) => {
  try {
    if (isHPO(networkCode)) return networkCode;

    const [network] = networkCode.split('_');
    return network || networkCode;
  } catch {
    return networkCode;
  }
};

export const isHPO = (network: string) => network === 'HPO';
