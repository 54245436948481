import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import React, { forwardRef } from 'react';

const Page = forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => {
  return (
    <Box ref={ref} p={['20px 12px', '20px 12px', '20px 16px', '40px 64px']} {...props}>
      {children}
    </Box>
  );
});

export default Page;
