export enum RouteConfig {
  Home = '/',
  Play = '/play',

  Maintenance = '/maintenance',

  HunnyPoker = 'https://hunnypoker.com/',
  TermOfService = 'https://docs.hunny.finance/products/hunnyplay/hunnyplay-terms-and-conditions',

  Twitter = 'https://twitter.com/HunnyPoker',
  Telegram = 'https://t.me/HunnyPoker',
  Medium = 'https://medium.com/hunnyfinance',
  Discord = 'https://discord.gg/hunnyplay',
}
