import { createSlice } from '@reduxjs/toolkit';
import { UserBonus } from 'config/types/bonus/userBonus';
import { resetUserBonus, updatePreparedBonusId, updateUserBonuses } from './actions';

export interface BonusState {
  userBonuses: UserBonus[];
  preparedBonusId: string;
}

export const initialState: BonusState = {
  userBonuses: [],
  preparedBonusId: null,
};

export const appSlice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserBonuses, (state, { payload }) => {
        const { userBonuses } = payload;
        state.userBonuses = userBonuses;
        return state;
      })
      .addCase(updatePreparedBonusId, (state, { payload }) => {
        state.preparedBonusId = payload;
        return state;
      })
      .addCase(resetUserBonus, (state) => {
        state.preparedBonusId = null;
        state.userBonuses = [];

        return state;
      });
  },
});

export default appSlice.reducer;
