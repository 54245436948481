import { createAction } from '@reduxjs/toolkit';
import { LoginMethod } from 'config/constants/auth';
import { WalletType } from 'config/types/wallet';

export const login = createAction<{
  username: string;
  uid: string;
  email: string;
  address: string;
}>('auth/login');

export const updateAuthToken = createAction<{
  accessToken: string;
  refreshToken: string;
}>('auth/updateAuthToken');

export const signedExecuteLogin = createAction<{
  loginBy: LoginMethod;
  wallet?: {
    type: WalletType;
    connectorId: string;
    name: string;
    address: string;
    trackingName: string;
  };
}>('auth/signedExecuteLogin');

export const logout = createAction('auth/logout');
export const removeEmail = createAction('auth/removeEmail');
